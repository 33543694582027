import { i18n } from "@/config"

const { availableLocales, setLocale, getLocale: currentLocale } = i18n

export default {
  data() {
    return {
      availableLocales
    }
  },

  computed: {
    currentLocale
  },

  methods: {
    setLocale
  }
}
